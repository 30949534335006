import React, { useState}  from 'react';
import './Request.css';
import { saveAs } from 'file-saver';
import axios from "axios";
import './modal.css'
import config from "../../config.js";
class Post extends React.Component {

constructor(props)
{
  super(props);
  this.state = {
    showModal: false,
    firstName: "",
    lastName: "",
    fatherName: "",
    docID: "",
    FIN: "",
    key: this.props.postID,
    mobileNumber: this.props.mobileNumber,
    money: this.props.money,
    time: this.props.time,
    dateOfBirth: "",
    isFinished: null

  }
  this.showModal = this.showModal.bind(this);
}
    showModal()
    {
      if(this.state.showModal)
      {
        this.setState({showModal: false})
      }
      else{
        this.setState({showModal: true})
      }
    }
    
    sendPost(state)
    {
        if(state.firstName == "" || state.lastName == "" || state.mobileNumber == "" || state.fatherName == "" || state.docID == "" || state.FIN == "" || state.money == "" || state.dateOfBirth == "" || state.time == "" || state.filename == "" )
        {
          alert("Bütün xanaların doldurulduğundan əmin olun");
        }
        else
        {
          console.log("Send post "+this.props.token)
        
        axios.post("http://"+config.ip+"/api/posts/newPost2", {
          token: this.props.token,
          idOfUser: state.mobileNumber,
          name: state.firstName,
          surname: state.lastName,
          fatherName: state.fatherName,
          docID: state.docID,
          finNumber: state.FIN,
          amountOfMoney: state.money,
          dateOfBirth: state.dateOfBirth,
          key: state.key
        }).then(res =>
          {
            this.setState({showModal: false});
            
            window.location.reload(false);
          })
        .catch(err => console.log("Error in send post" + err))

        }
        
    }

    deleteFile() {
      axios.post("http://" + config.ip + "/api/files/deleteFile", {
        key: this.state.key,
        token: this.props.token,
      }).then(res => {
        window.location.reload()
      })
      .catch(err => console.log("Error sending post" + err))
    }
    render()
    {
      const showHideClassName = this.state.showModal ? "modal display-block" : "modal display-none";
      var date = new Date(this.props.date);

    return (
        <div className="postWaiting">
            
                <p style={{width: '15%'}}>{this.props.name}</p>
                <p style={{width: '15%', marginLeft: '15px'}}>{this.props.money} ₼</p>
                <p style={{width: '15%'}}>{this.props.city}</p>
                <p style={{width: '15%'}}>{this.props.mobileNumber}</p>
                <p style={{width: '8%'}}>{date.getHours() + ":" + date.getMinutes()}</p>
                <p style={{width: '15%'}}>{date.toLocaleDateString()}</p>

            {<div className={showHideClassName}>
              <section className="modal-Form">

                  <input style={{fontSize: 23}} type="text" name="name" placeholder='FIN' value={this.state.FIN} onChange={(event) => {console.log(event); this.setState({FIN: event.target.value})} }/>
                  <br/>
                  <input style={{fontSize: 23}} type="text" name="name" placeholder='Sənədin nömrəsi' value={this.state.docID} onChange={(event) => {console.log(event); this.setState({docID: event.target.value})} }/>
                  <br/>
                    <input style={{fontSize: 23}} type="text" name="name" placeholder='Ad' value={this.state.firstName} onChange={(event) => this.setState({firstName: event.target.value})}/>
                    <br/>
                    <input style={{fontSize: 23}} type="text" name="name" placeholder='Soyad' value={this.state.surname} onChange={(event) => this.setState({lastName: event.target.value})}/>
                    <br/>
                    <input style={{fontSize: 23}} type="text" name="name" placeholder='Ata adı' value={this.state.fatherName} onChange={(event) => this.setState({fatherName: event.target.value})}/>
                    <br/>
                    <input style={{fontSize: 23}} type="text" name="name" placeholder='Doğum tarixi' value={this.state.dateOfBirth} onChange={(event) => this.setState({dateOfBirth: event.target.value})}/>
                    <br/>
                  <button style={{fontSize: 30}} onClick={() => this.setState({showModal: false})}>Bağla <img src={require('../../../assets/icons/multiply.png')}/></button>
                  <button style={{fontSize: 30}} onClick={()=> this.sendPost(this.state)}>Göndər <img src={require('../../../assets/icons/check.png')}/></button>
                  
                
              </section>
            </div>}
            <button onClick={this.showModal}><img src={require('./../../../assets/icons/form.png')}/></button>
            </div> 
    )
}
}

export default Post;
